@font-face {
  font-family: iransans;
  src: url(./assets/fonts/IRANSansWeb_UltraLight.woff) format("woff");
  font-weight: 100;
}

@font-face {
  font-family: iransans;
  src: url(./assets/fonts/IRANSansWeb_Light.woff) format("woff");
  font-weight: 200;
}

@font-face {
  font-family: iransans;
  src: url(./assets/fonts/IRANSansWeb_Medium.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: iransans;
  src: url(./assets/fonts/IRANSansWeb_Medium.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: iransans;
  src: url(./assets/fonts/IRANSansWeb_Bold.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: iransans;
  src: url(./assets/fonts/IRANSansWeb_Black.woff) format("woff");
  font-weight: 600;
}


body {
  margin: 0;
  font-family: iransans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'IRANSansWeb', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  direction: rtl;
  font-family: iransans;
}
